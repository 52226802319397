import React from "react";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";

import {
  Box,
  SEO,
  Theme,
  ScheduleCallout,
  PrismicLink,
  FlexCol,
  RichText,
  Button,
  SectionWrapper,
} from "src/components";

import Lineup from "./_components/_lineup";
import Empty from "./_components/_empty";

function FestivalLineup({ data, setInvert, setInvertLogo, preview }) {
  let stages = [];
  if (data.stages) {
    data.stages.edges.forEach((stage) => {
      stages.push({
        ...JSON.parse(stage.node.dataString),
        id: stage.node.prismicId,
      });
    });
  }
  let artists = [];
  if (data.artists) {
    data.artists.edges.forEach((artist) => {
      artists.push({
        ...JSON.parse(artist.node.dataString),
        id: artist.node.prismicId,
      });
    });
  }
  let festival = JSON.parse(data.festival.dataString);
  festival.schedule.forEach((lineupItem) => {
    if (lineupItem.primary.performer && lineupItem.primary.performer.id) {
      artists.forEach((artist) => {
        if (artist.id === lineupItem.primary.performer.id) {
          lineupItem.performerData = artist;
        }
      });
    }
  });
  let activeFestival = {};
  if (data.activeFestival) {
    activeFestival = JSON.parse(data.activeFestival.dataString);
  }
  return (
    <>
      <ThemeProvider
        theme={Theme({
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          <SEO
            title={"Lineup | " + PrismicRichText.asText(festival.title)}
            description={
              festival.opengraph_description &&
              PrismicRichText.asText(festival.opengraph_description)
            }
            image={
              festival.opengraph_image &&
              festival.opengraph_image.url &&
              festival.opengraph_image.url
            }
            imageAlt={
              festival.opengraph_image &&
              festival.opengraph_image.alt &&
              festival.opengraph_image.alt
            }
          />
          <Box
            css={`
              overflow: hidden;
            `}
          >
            {/* {festival.schedule && festival.schedule.length > 0 && ( */}
            <Lineup
              festival={festival}
              preview={preview}
              activeFestival={activeFestival}
              setInvert={setInvert}
              setInvertLogo={setInvertLogo}
              uid={data.festival.uid}
              schedule={festival.schedule}
              id={data.festival.id ? data.festival.id : data.festival.prismicId}
              stages={stages}
              title={
                festival.lineup_header
                  ? PrismicRichText.asText(festival.lineup_header)
                  : "Lineup"
              }
              incompleteDescription={festival.schedule_incomplete_prompt}
            />
            {/* )} */}
          </Box>
        </>
      </ThemeProvider>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5),
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          {/* TEMP CHANGE FOR SUMMER SERENADES */}
          {/* {((activeFestival.festival_online === "Yes" &&
            activeFestival.ongoing_festival &&
            activeFestival.ongoing_festival.uid === data.festival.uid) ||
            preview) && (
            <>
             {(!festival.schedule || festival.schedule.length === 0) && <Empty />} */}
          {/* {festival.schedule_complete === "Yes" && (
                <ScheduleCallout festival={data.festival.uid} />
              )} 
            </>
          )}*/}
          {((activeFestival.ongoing_festival &&
            activeFestival.ongoing_festival.uid !== data.festival.uid) ||
            activeFestival.festival_online === "No") && (
            <SectionWrapper
              id="ticketsNotAvailable"
              maxWidth={2}
              py={[8, 9, 10]}
              bg="bg.default"
            >
              {activeFestival.festival_offline_message &&
                activeFestival.festival_offline_message[0] && (
                  <Box mb={4} maxWidth={600} mx="auto">
                    <RichText textAlign="center" fontSize={3}>
                      {activeFestival.festival_offline_message &&
                        PrismicRichText.asText(
                          activeFestival.festival_offline_message
                        )}
                    </RichText>
                  </Box>
                )}
              {activeFestival.festival_offline_button_text &&
                activeFestival.festival_offline_button_link &&
                activeFestival.festival_offline_button_link.link_type && (
                  <FlexCol alignItems="center">
                    <Button
                      children={activeFestival.festival_offline_button_text}
                      as={PrismicLink}
                      link={activeFestival.festival_offline_button_link}
                      variant={"primary"}
                    />
                  </FlexCol>
                )}
            </SectionWrapper>
          )}
        </>
      </ThemeProvider>
    </>
  );
}

export default FestivalLineup;

export const query = graphql`
  query ($id: String!) {
    activeFestival: prismicActiveFestival {
      dataString
    }
    festival: prismicFestival(id: { eq: $id }) {
      prismicId
      uid
      dataString
    }
    stages: allPrismicStage {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
    artists: allPrismicArtist {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
  }
`;
