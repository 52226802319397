import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import moment from "moment-timezone";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";
import { ThemeProvider } from "styled-components";

import {
  Box,
  FlexCol,
  FlexRow,
  FlexGrid,
  H2,
  H4,
  PageHeaderV2,
  PrismicImage,
  SectionWrapper,
  Text,
  RadioButton,
  Theme,
} from "src/components";

const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

const Tag = ({ children }) => (
  <Box bg="brand.primary" p={["4px", "8px"]}>
    <Text
      children={children}
      color="text.default"
      fontSize={["10px", "12px"]}
      fontWeight={800}
      css={`
        text-transform: uppercase;
      `}
    />
  </Box>
);

function Lineup({
  preview,
  activeFestival,
  uid,
  id,
  schedule,
  stages,
  title,
  incompleteDescription,
  setInvert,
  setInvertLogo,
  festival,
}) {
  const [filter, setFilter] = useState(undefined);

  const [sortedSchedule, setSortedSchedule] = useState([]);

  const [days] = useState(() => {
    let days = [{ date: "A-Z", label: "A-Z" }];
    schedule.forEach((scheduleItem) => {
      if (scheduleItem.primary.start_time) {
        let date = moment(scheduleItem.primary.start_time)
          .tz("America/Edmonton")
          .dayOfYear();
        let day =
          dayLabels[
            moment(scheduleItem.primary.start_time).tz("America/Edmonton").day()
          ];
        let found = false;
        days.forEach((day) => {
          if (day.date === date) {
            found = true;
          }
        });
        if (!found) {
          days.push({ label: day, date: date });
        }
      }
    });
    days = days.sort((a, b) => {
      return a.date - b.date;
    });
    return days;
  });

  useEffect(() => {
    if (window.location) {
      if (window.location.search.indexOf("?day=") !== -1) {
        setFilter(parseInt(window.location.search.replace("?day=", "")));
      } else {
        setFilter("A-Z");
      }
    }
  }, []);

  useEffect(() => {
    if (filter) {
      let sortedSchedule = [];
      if (filter === "A-Z") {
        window.history.replaceState(null, null, window.location.pathname);
        let azSchedule = JSON.parse(JSON.stringify(schedule));
        let stageSchedule = [];
        azSchedule.forEach((scheduleItem) => {
          if (scheduleItem.slice_type === "concert") {
            let found = false;
            stageSchedule.forEach((existingItem) => {
              if (
                existingItem.primary.performer &&
                scheduleItem.primary.performer &&
                existingItem.primary.performer.id ===
                  scheduleItem.primary.performer.id
              ) {
                found = true;
              }
            });
            if (!found) {
              stageSchedule.push(scheduleItem);
            }
          }
        });
        stageSchedule = stageSchedule.sort((a, b) => {
          if (
            (a.primary.performer &&
              a.primary.performer.slug &&
              a.primary.performer.slug.replace("the-", "")) <
            (b.primary.performer &&
              b.primary.performer.slug &&
              b.primary.performer.slug.replace("the-", ""))
          )
            return -1;
          if (
            (a.primary.performer &&
              a.primary.performer.slug &&
              a.primary.performer.slug.replace("the-", "")) >
            (b.primary.performer &&
              b.primary.performer.slug &&
              b.primary.performer.slug.replace("the-", ""))
          )
            return 1;
          return 0;
        });
        sortedSchedule.push({ name: "A-Z", schedule: stageSchedule });
      } else if (filter) {
        window.history.replaceState(
          null,
          null,
          window.location.pathname + "?day=" + filter
        );
        stages.forEach((stage) => {
          if (stage.stage_name) {
            if (stage.festival) {
              console.log(id);
              if (stage.festival.id === id) {
                let stageSchedule = [];
                schedule.forEach((scheduleItem, index) => {
                  if (
                    scheduleItem.primary.stage &&
                    scheduleItem.slice_type === "concert"
                  ) {
                    if (scheduleItem.primary.start_time) {
                      if (
                        filter ===
                          moment(scheduleItem.primary.start_time)
                            .tz("America/Edmonton")
                            .dayOfYear() &&
                        scheduleItem.primary.stage.id === stage.id
                      ) {
                        let found = false;
                        stageSchedule.forEach((existingItem) => {
                          if (
                            existingItem.primary.performer &&
                            scheduleItem.primary.performer &&
                            existingItem.primary.performer.id ===
                              scheduleItem.primary.performer.id
                          ) {
                            found = true;
                          }
                        });
                        if (!found) {
                          stageSchedule.push(scheduleItem);
                        }
                      }
                    }
                  }
                });
                if (stageSchedule.length !== 0) {
                  stageSchedule = stageSchedule.sort((a, b) => {
                    if (
                      (a.primary.start_time &&
                        moment(a.primary.start_time).unix()) <
                      (b.primary.start_time &&
                        moment(b.primary.start_time).unix())
                    )
                      return -1;
                    if (
                      (a.primary.start_time &&
                        moment(a.primary.start_time).unix()) >
                      (b.primary.start_time &&
                        moment(b.primary.start_time).unix())
                    )
                      return 1;
                    return 0;
                  });
                  sortedSchedule.push({
                    name: PrismicRichText.asText(stage.stage_name),
                    subName: stage.stage_subname,
                    number: stage.stage_number,
                    logo: stage.sponsor_logo,
                    schedule: stageSchedule,
                  });
                }
              }
            }
          }
        });
      }
      sortedSchedule = sortedSchedule.sort((a, b) => {
        return a.number - b.number;
      });
      setSortedSchedule(sortedSchedule);
    }
  }, [filter]);

  return (
    <>
      <PageHeaderV2
        heading={title}
        color="brand.primary"
        invert={true}
        setInvert={setInvert}
        setInvertLogo={setInvertLogo}
      >
        {/* <Box py={[7, 9]}>
          <H6 children={"Lineup"} mb={5} />
          <FlexGrid
            justifyContent="space-between"
            gutterX={[7, 9]}
            gutterY={[5]}
          >
            <FlexCol>
              <H1 children={title} fluid />
            </FlexCol>
            <FlexRow
              justifyContent={["center", "flex-end"]}
              alignItems="center"
            >
              <Box>
                <RadioButton
                  options={days}
                  value={filter}
                  valueProp="date"
                  labelProp="label"
                  onChange={setFilter}
                />
              </Box>
            </FlexRow>
          </FlexGrid>
        </Box> */}
      </PageHeaderV2>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5),
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <Box bg="bg.default">
          {((activeFestival.festival_online === "Yes" &&
            activeFestival.ongoing_festival &&
            activeFestival.ongoing_festival.uid === uid) ||
            preview) && (
            <>
              <SectionWrapper
                css={`
                  min-height: 100vh;
                `}
              >
                <FlexCol py={4} alignItems="center">
                  <RadioButton
                    options={days}
                    value={filter}
                    valueProp="date"
                    labelProp="label"
                    onChange={setFilter}
                  />
                </FlexCol>
                {sortedSchedule.map((stage, index) => {
                  return (
                    <FlexCol
                      py={[5, null, 7]}
                      css={`
                        border-top: 1px solid
                          ${(props) => props.theme.colors.bg.alt};
                        overflow: hidden;
                      `}
                    >
                      <FlexGrid
                        gutterX={[3, 5]}
                        gutterY={[5]}
                        key={"stage" + index}
                      >
                        <FlexCol width={[1 / 2, 1 / 3]}>
                          {/* <FlexCol maxWidth={200}>
                            {stage.logo && (
                              <PrismicImage
                                image={stage.logo}
                                alt={
                                  stage.logo && stage.logo.alt && stage.logo.alt
                                }
                                sizes="200px"
                              />
                            )}
                          </FlexCol> */}
                          <H2 children={stage.name} />
                          <H4
                            mt={1}
                            children={
                              stage.subName
                                ? "\n" + stage.subName
                                : stage.number
                                ? stage.number === 0
                                  ? ""
                                  : ` ⁠Stage ` + stage.number
                                : ""
                            }
                          />
                        </FlexCol>
                        {stage.schedule.map((scheduleItem, index) => {
                          if (scheduleItem.performerData) {
                            switch (scheduleItem.slice_type) {
                              case "concert":
                                return (
                                  <FlexCol
                                    width={[1 / 2, 1 / 3]}
                                    key={
                                      "concert" +
                                      index +
                                      (scheduleItem.primary.performer
                                        ? scheduleItem.primary.performer.uid
                                        : "")
                                    }
                                    as={Link}
                                    to={
                                      "/" +
                                      uid +
                                      "/artist/" +
                                      scheduleItem.primary.performer.uid
                                    }
                                    position="relative"
                                  >
                                    {scheduleItem.performerData && (
                                      <>
                                        {!scheduleItem.performerData.image
                                          .url && (
                                          <Box
                                            width="100%"
                                            pb={"75%"}
                                            bg="bg.alt"
                                          />
                                        )}
                                        {scheduleItem.performerData.image
                                          .url && (
                                          <PrismicImage
                                            image={
                                              scheduleItem.performerData.image
                                            }
                                            alt={
                                              scheduleItem.performerData
                                                .artist_name &&
                                              PrismicRichText.asText(
                                                scheduleItem.performerData
                                                  .artist_name
                                              )
                                            }
                                            placeholder={
                                              <div
                                                css={`
                                                  height: 100%;
                                                  width: 100%;
                                                  background-color: ${(props) =>
                                                    props.theme.color.bg.wash};
                                                `}
                                              />
                                            }
                                            sizes="200px"
                                          />
                                        )}
                                        {scheduleItem.performerData
                                          .featured_tagline && (
                                          <FlexRow
                                            css={`
                                              position: absolute;
                                            `}
                                            bottom={[46, 48]}
                                            left={[8, 16]}
                                          >
                                            <Tag
                                              children={
                                                scheduleItem.performerData
                                                  .featured_tagline
                                              }
                                            />
                                          </FlexRow>
                                        )}
                                        {scheduleItem.performerData
                                          .artist_name && (
                                          <Text
                                            children={PrismicRichText.asText(
                                              scheduleItem.performerData
                                                .artist_name
                                            )}
                                            mt={2}
                                            fontSize={[1, 2]}
                                            fontWeight={600}
                                          />
                                        )}
                                      </>
                                    )}
                                    {/* {scheduleItem.primary.start_time &&
                            filter === "A-Z" && (
                              <Text
                                children={moment(
                                  scheduleItem.primary.start_time
                                ).format("dddd")}
                              />
                            )} */}
                                    {/* {!scheduleItem.primary.start_time && (
                        <Text children="TBD" />
                      )} */}
                                  </FlexCol>
                                );

                              default:
                                return null;
                            }
                          } else {
                            return null;
                          }
                        })}
                      </FlexGrid>
                    </FlexCol>
                  );
                })}
              </SectionWrapper>
              {incompleteDescription && incompleteDescription[0] && (
                <Box bg="bg.wash">
                  <SectionWrapper>
                    <Box py={[7, 9]} textAlign="center">
                      <Text fontSize={2} fluid>
                        {incompleteDescription}
                      </Text>
                    </Box>
                  </SectionWrapper>
                </Box>
              )}
            </>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}

export default Lineup;
